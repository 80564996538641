<template>
  <div class="about">
    <div class="section hero-grey">
      <div class="container">
        <div class="small-container center">
          <h2 class="h2 margin">Noticias y Eventos</h2>
          <p>
            Actividades que realiza la Dirección General de Formación de
            Maestros
          </p>
        </div>
      </div>
    </div>
    <div class="section padding-bottom hero-greys">
      <div class="container">
        <div class="w-dyn-list">
          <div role="list" class="posts-grid-v-2 w-dyn-items">
            <div
              role="listitem"
              class="w-dyn-item"
              v-for="(item, index) in noticias"
              :key="index"
            >
              <div class="post-card-v-3">
                <div class="post-image-container">
                  <!-- https://uploads-ssl.webflow.com/6084703c27241e37743ca755/608470d92f8b8e652a061184_606b31f9152165b0c05a4b5a_Team_Work.jpeg -->
                  <img
                    v-if="item.imagen"
                    :src="`${serve}/noticias/${item.imagen}`"
                    width="100%"
                    height="100%"
                    loading="lazy"
                    alt="Noticia"
                    class="post-image-v-2"
                  />
                </div>
                <div class="post-content-v-2">
                  <div class="small-text">
                    Fecha {{ item.fecha | formatdate }}
                  </div>
                  <h4 class="h4">
                    {{ item.titulo }}
                  </h4>
                  <p class="small-text">
                    {{ item.descripcion }}
                    <!-- .substring(0, 200) + "..." -->
                  </p>
                  <router-link
                    to=""
                    @click.native="redirectDetail(item.id)"
                    class="link"
                  >
                    Ver más
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Service from "../router/endpoint";
import axios from "axios";
export default {
  name: "news",
  data() {
    return {
      loading: false,
      noticias: [],
      serve: "",
    };
  },
  mounted() {
    this.serve = Service.getServe();
    this.getNoticias();
  },
  methods: {
    getNoticias() {
      this.loading = true;
      axios
        .get(Service.getBase() + "lista/noticias", Service.getHeader())
        .then((response) => {
          this.loading = false;
          this.noticias = response.data;
        })
        .catch((error) => {
          this.loading = false;
          console.error("Error al cargar registros", error);
        });
    },

    redirectDetail(noticia_id) {
      Service.setNewsId(noticia_id);
      this.$router.push({ name: "news_detail" });
    },
  },
};
</script>

<style scoped></style>
